"use client";
import styles from "./page.module.css";
import Link from "next/link";

export default function Error() {
  return (
    <main className={styles.errorPage}>
      <h2>Something went wrong</h2>
      <div>
        This page must have taken a data detour! No need to query further - just
        plot a course back to our homepage 🧭
      </div>
      <Link href="/" className={styles.errorPageBtn} prefetch={false}>
        Return Home
      </Link>
    </main>
  );
}
